import "../scss/index.scss";

// utils
import foundation from "../js/_utils/foundation";
import BrowserCheck from "../js/_utils/BrowserCheck";
import Ease from "../js/_utils/Ease";

// libs
import Modal from "../js/_libs/Modal";
import SplitText from "../js/_libs/SplitText";
import ScrollObserver from "../js/_libs/ScrollObserver";
import AnchorScroll from "../js/_libs/AnchorScroll";

// npm
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import "@splidejs/splide/css";
import { Splide } from "@splidejs/splide";
import { Intersection } from "@splidejs/splide-extension-intersection";

import Lenis from "@studio-freight/lenis";

document.addEventListener("DOMContentLoaded", () => {
	if (window.matchMedia("(min-width: 768px)").matches) {
		const lenis = new Lenis();

		lenis.on("scroll", ScrollTrigger.update);

		gsap.ticker.add((time) => {
			lenis.raf(time * 1000);
		});

		gsap.ticker.lagSmoothing(0);
		// アンカーリンクを取得
		const anchors = document.querySelectorAll(".js-anchor-scroll");
		anchors.forEach((anchor) => {
			anchor.addEventListener("click", (e) => {
				// デフォルトの挙動を防ぐ
				e.preventDefault();

				// クリックされたアンカーのhref属性を取得
				const targetId = anchor.getAttribute("href");

				// スクロール先の要素が存在するか確認
				const targetElement = document.querySelector(targetId);

				if (targetElement) {
					// 要素が存在する場合、その要素までスクロール
					lenis.scrollTo(targetId);
				} else {
					// 要素が存在しない場合、コンソールにエラーを出力
					console.error(`Target element ${targetId} not found`);
				}
			});
		});

		gsap.fromTo(
			".js-about-target",
			{
				y: "-150vh",
				willChange: "transform",
				backfaceVisibility: "hidden",
			},
			{
				y: 0,
				ease: "none",
				scrollTrigger: {
					trigger: ".js-about-trigger",
					start: "top top",
					end: "bottom top",
					scrub: true,
				},
			}
		);

		// page top
		const pageTops = document.querySelectorAll(".js-page-top");

		pageTops.forEach((pageTop) => {
			pageTop.addEventListener("click", (e) => {
				e.preventDefault();

				lenis.scrollTo(0, {
					duration: 3,
				});
			});
		});
	} else {
		AnchorScroll(".js-anchor-scroll");
	}

	// オープニング
	opening();

	// メニュー
	Modal({
		modalCass: ".js-menu",
		openClass: ".js-menu-button",
		closeClass: ".js-close-button",
		htmlClass: "is-menu-open",
		globalContainer: ".js-main",
	});

	// split text
	new SplitText(".js-title-target", {
		target: ".js-title-text",
		animation: [90],
	});

	gsap.fromTo(
		".js-fv-cake",
		{
			yPercent: 0,
			scale: 1,
		},
		{
			yPercent: 100,
			scale: 0.65,
			scrollTrigger: {
				trigger: ".js-fv-cake-tigger",
				start: "top top",
				end: "bottom 50%",
				scrub: 0.1,
			},
		}
	);

	// プログレスバー
	let firstcake = "none";

	document.body.setAttribute("data-scroll-cake", firstcake);

	const scrollObserver_cake = new ScrollObserver({
		target: ".js-chage-cake",
		callback: (entry) => {
			if (entry.isIntersecting) {
				const cake = entry.target.dataset.cake;
				document.body.setAttribute("data-scroll-cake", cake);
			}
		},
		option: {
			rootMargin: "0px 0px 10% 0px",
		},
		first: 0,
	});
	// 監視開始;
	scrollObserver_cake.init();

	const scrollObserver_block = new ScrollObserver({
		target: ".js-block-target",
		addClass: "is-active",
		once: true,
		option: {
			rootMargin: "0px 0px 10% 0px",
		},
	});
	// 監視開始;
	scrollObserver_block.init();

	// 線の描画
	drowLine();

	// 固定表示
	kotei();
	// 動画の再生
	/**
	 * @type {HTMLVideoElement}
	 */
	const loopVideo = document.querySelector(".js-video-loop");

	const playPromise = loopVideo.play();

	function playVideo() {
		if (playPromise !== undefined) {
			playPromise
				.then(() => {
					setTimeout(() => {
						loopVideo.play();
					}, 1600);
				})
				.catch(() => {
					document.documentElement.classList.add("is-less");
				});
		}
	}
	playVideo();

	// パララックス
	let paraEle = 10;
	if (window.matchMedia("(min-width: 768px)").matches) {
		paraEle = 15;
	}

	for (let i = 1; i <= 2; i++) {
		let parallaxTrigger = document.querySelector(".js-parallax-trigger" + i);
		let parallaxTarget = document.querySelector(".js-parallax-target" + i);

		gsap.to(parallaxTarget, {
			yPercent: paraEle,
			ease: "none",
			scrollTrigger: {
				trigger: parallaxTrigger,
				start: "top",
				end: "bottom ",
				scrub: true,
			},
		});
	}

	const parallaxTrigger = document.querySelector(".js-about-item-trigger");

	gsap.fromTo(
		".js-parallax-circle",
		{
			yPercent: 0,
		},
		{
			yPercent: -30,
			ease: "none",
			scrollTrigger: {
				trigger: parallaxTrigger,
				start: "top 10%",
				end: "bottom 10%",
				scrub: true,
			},
		}
	);
	gsap.fromTo(
		".js-parallax-sugar",
		{
			yPercent: 0,
		},
		{
			yPercent: -50,
			ease: "none",
			scrollTrigger: {
				trigger: parallaxTrigger,
				start: "top bottom",
				end: "bottom top",
				scrub: true,
			},
		}
	);
	gsap.fromTo(
		".js-parallax-lemon",
		{
			yPercent: 0,
		},
		{
			yPercent: -120,
			ease: "none",
			scrollTrigger: {
				trigger: parallaxTrigger,
				start: "top bottom",
				end: "bottom top",
				scrub: true,
			},
		}
	);

	gsap.utils.toArray(".js-parallax-wide-trigger").forEach((wrap) => {
		const parallaxTarget = wrap.querySelector(".js-parallax-wide-target");
		gsap.to(parallaxTarget, {
			yPercent: -13,
			ease: "none",
			scrollTrigger: {
				trigger: wrap,
				start: "top bottom",
				end: "bottom top",
				scrub: true,
			},
		});
	});

	// スライダー
	const shopSplide = new Splide(".splide", {
		type: "fade",
		rewind: true,
		arrows: false,
		autoplay: "pause",
		interval: 5000,
		speed: 2000,
		intersection: {
			inView: {
				autoplay: true,
			},
			outView: {
				autoplay: false,
			},
		},
		classes: {
			pagination: "splide__pagination",
			page: "splide__pagination__page",
		},
	});
	shopSplide.mount({ Intersection });

	gsap.to(".js-staggers-target", {
		scrollTrigger: {
			trigger: ".js-staggers-trigger",
			start: "top-=700 top",
		},
		maskPosition: "0 100%",
		stagger: 0.1,
	});

	const scrollObserver_header = new ScrollObserver({
		target: ".js-description",
		callback: (entry) => {
			if (entry.isIntersecting) {
				const headerButtons = document.querySelectorAll(".js-menu-button");
				const fixButtons = document.querySelectorAll(".js-fix-button");

				// 各ボタンに is-show クラスを追加
				headerButtons.forEach((button) => {
					button.classList.add("is-move");
				});
				fixButtons.forEach((button) => {
					button.classList.add("is-move");
				});
			} else {
				// フッターが画面外に出た時の処理（オプション）
				const headerButtons = document.querySelectorAll(".js-menu-button");
				const fixButtons = document.querySelectorAll(".js-fix-button");
				headerButtons.forEach((button) => {
					button.classList.remove("is-move");
				});
				fixButtons.forEach((button) => {
					button.classList.remove("is-move");
				});
			}
		},
		option: {
			rootMargin: "1px 0px 0px 0px",
		},
		first: 0,
	});
	// 監視開始;
	scrollObserver_header.init();

	const scrollObserver_bottom = new ScrollObserver({
		target: ".js-header-move",
		callback: (entry) => {
			if (entry.isIntersecting) {
				const headerButtons = document.querySelectorAll(".js-menu-button");
				const fixButtons = document.querySelectorAll(".js-fix-button");

				// 各ボタンに is-show クラスを追加
				headerButtons.forEach((button) => {
					button.classList.add("is-move-bottom");
				});
				fixButtons.forEach((button) => {
					button.classList.add("is-move-bottom");
				});
			} else {
				const headerButtons = document.querySelectorAll(".js-menu-button");
				const fixButtons = document.querySelectorAll(".js-fix-button");
				headerButtons.forEach((button) => {
					button.classList.remove("is-move-bottom");
				});
				fixButtons.forEach((button) => {
					button.classList.remove("is-move-bottom");
				});
			}
		},
		option: {
			rootMargin: "1px 0px 0px 0px",
		},
		first: 0,
	});
	// 監視開始;
	scrollObserver_bottom.init();

	const scrollObserver_footer = new ScrollObserver({
		target: ".js-footer",
		callback: (entry) => {
			if (entry.isIntersecting) {
				const pageTopButtons = document.querySelectorAll(".js-page-top");

				// 各ボタンに is-show クラスを追加
				pageTopButtons.forEach((button) => {
					button.classList.add("is-show");
				});
			} else {
				// フッターが画面外に出た時の処理（オプション）
				const pageTopButtons = document.querySelectorAll(".js-page-top");
				pageTopButtons.forEach((button) => {
					button.classList.remove("is-show");
				});
			}
		},
		option: {
			rootMargin: "0px 0px 1px 0px",
		},
		first: 0,
	});
	// 監視開始;
	scrollObserver_footer.init();
});

window.addEventListener("load", () => {
	foundation();
	const html = document.documentElement;
	const BrowserCheckObj = BrowserCheck();
	// タッチデバイスの場合
	if (BrowserCheckObj?.phone || BrowserCheckObj?.tablet) {
		html.classList.add("isTouchable");
	}
	// iosの場合
	if (BrowserCheckObj?.apple.device) {
		html.classList.add("isIos");
	}
	// Safariの場合
	if (BrowserCheckObj?.safari) {
		html.classList.add("isSafari");
	}

	if (isTablet()) {
		html.classList.add("isTablet");
	}

	document.querySelector(".js-menu").classList.add("isLoaded");
});

function isTablet() {
	const userAgent = navigator.userAgent.toLowerCase();

	// タブレットを示すユーザーエージェント文字列の一部をチェック
	const isTabletDevice =
		/ipad|android(?!.*mobile)|tablet|kindle|silk|playbook|nexus 7|nexus 10|xoom|sch-i800|playbook|tablet|kindle|silk/i.test(
			userAgent
		);

	return isTabletDevice;
}

function drowLine() {
	// 固定の線の描画
	gsap
		.timeline({
			scrollTrigger: {
				trigger: ".js-description",
				start: "top center",
				toggleActions: "play none none reset",
			},
		})
		.add(() => {
			document.querySelector(".js-fix-button").classList.add("is-show");
		})
		.to(
			".js-line-1-left",
			{
				scaleX: 1,
				duration: 0.3,
			},
			"<"
		)
		.to(
			".js-line-1-right",
			{
				scaleX: 1,
				duration: 0.3,
			},
			"<"
		)
		.to(
			".js-line-2-left",
			{
				"--line-percent": "-100%",
				duration: 0.3,
			},
			"=-0.15"
		)
		.to(
			".js-line-2-right",
			{
				"--line-percent": "-100%",
				duration: 0.3,
			},
			"<"
		)
		.to(
			".js-line-3-left",
			{
				scaleY: 1,
				duration: 0.6,
			},
			"=-0.1"
		)
		.to(
			".js-line-3-right",
			{
				scaleY: 1,
				duration: 0.6,
			},
			"<"
		)
		.to(
			".js-line-4-left",
			{
				"--line-percent": "-100%",
				duration: 0.3,
			},
			"=-0.1"
		)
		.to(
			".js-line-4-right",
			{
				"--line-percent": "-100%",
				duration: 0.3,
			},
			"<"
		)
		.to(
			".js-line-5-left",
			{
				scaleX: 1,
				duration: 0.45,
			},
			"=-0.1"
		)
		.to(
			".js-line-5-right",
			{
				scaleX: 1,
				duration: 0.45,
			},
			"<"
		)
		.to(
			".js-progressbar",
			{
				opacity: 1,
				duration: 1,
			},
			"=+0.2"
		);
}
function opening() {
	const html = document.documentElement;

	// 背面固定
	const backLock = (isLoack) => {
		const styles = {
			position: "fixed",
			top: "0",
			left: "0",
			width: "100%",
			height: "100%",
			overflow: "hidden scroll",
		};

		Object.keys(styles).forEach((key) => {
			document.documentElement.style[key] = isLoack ? styles[key] : "";
		});
	};

	const tl = gsap.timeline();
	tl.add(() => {
		html.classList.add("isOpening1");
		backLock(true);
	}, "<")
		.fromTo(
			".js-op",
			{
				opacity: 0,
			},
			{
				opacity: 1,
				duration: 0.5,
			},
			"+=0.5"
		)
		.to(
			".js-opening1",
			{
				opacity: 1,
				ease: Ease.sine2.Out,
				duration: 0.5,
			},
			"+=0.3"
		)
		.to(
			".js-opening-text",
			{
				opacity: 1,
				ease: Ease.sine2.Out,
				duration: 0.5,
			},
			"<"
		)
		.add(() => {
			html.classList.add("isOpening2");
		}, "<")
		.to(
			".js-opening3",
			{
				opacity: 1,
				ease: "none",
				duration: 0.1,
			},
			"+=0.2"
		)
		.to(
			".js-opening2",
			{
				opacity: 1,
				ease: "none",
				duration: 0.1,
			},
			"+=0.4"
		)
		.add(() => {
			html.classList.add("isOpening3");
		}, "<")
		.to(
			".js-opening2",
			{
				opacity: 0,
				ease: "none",
				duration: 0.1,
			},
			"+=0.2"
		)
		.to(
			".js-opening2",
			{
				opacity: 1,
				ease: "none",
				duration: 0.1,
			},
			"+=0.2"
		)
		.to(
			".js-opening2",
			{
				opacity: 0,
				ease: "none",
				duration: 0.1,
				onComplete: () => {
					backLock(false);
				},
			},
			"+=0.2"
		)
		.to(
			".js-opening2",
			{
				opacity: 1,
				ease: "none",
				duration: 0.1,
				onComplete: () => {
					backLock(false);
				},
			},
			"+=0.2"
		)
		.add(() => {
			html.classList.add("isOpeningEnd");
		})
		.add(() => {
			document.querySelector(".js-fv").classList.add("is-show");
		})
		.to(".js-op-inner", {
			yPercent: -150,
			rotate: -18,
			ease: Ease.sine2.out,
			duration: 2.8,
		})
		.add(() => {
			document.querySelector(".js-op").classList.add("is-hidden");
		});
}

function kotei() {
	const targetElement = document.querySelectorAll(".js-item");
	const targetHight = targetElement.length;
	/**
	 * @type {HTMLElement}
	 */
	const styleElement = document.querySelector(".js-description");
	styleElement.style.setProperty("--n-element", targetHight.toString());

	const pinElement = document.querySelector(".js-pin");
	const triggers = document.querySelectorAll(".js-trigger");
	const dropTriggers = document.querySelectorAll(".js-drop-trigger");
	pinElement.setAttribute("data-element", `many`);

	triggers.forEach((trigger, i) => {
		ScrollTrigger.create({
			trigger: trigger,
			start: "top top",
			end: "bottom bottom",
			onEnter: () => {
				pinElement.setAttribute("data-index", `${i + 1}`);
			},
			onLeaveBack: () => {
				if (i > 0) {
					pinElement.setAttribute("data-index", `${i}`);
				}
			},
		});
	});
	dropTriggers.forEach((dropTriggers, i) => {
		ScrollTrigger.create({
			trigger: dropTriggers,
			start: "top top",
			end: "bottom bottom",
			onEnter: () => {
				pinElement.setAttribute("data-drop", `${i + 1}`);
			},
			onLeaveBack: () => {
				if (i > 0) {
					pinElement.setAttribute("data-drop", `${i}`);
				}
			},
		});
	});

	gsap.to(".js-move-hand", {
		yPercent: -300,
		xPercent: 50,
		scale: 0.7,
		scrollTrigger: {
			trigger: ".js-move-hand-trigger",
			start: "top bottom",
			end: "bottom 10%",
			scrub: true,
		},
	});
}
