// 初期設定
function init() {
	// brタグの読み上げ防止
	const elements = document.querySelectorAll("br");
	elements.forEach((el) => {
		el.setAttribute("aria-hidden", "true");
	});

	// 画面の表示部分の高さ取得
	const setFillHeight = () => {
		const vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty("--vh", `${vh}px`);
	};
	setFillHeight();

	// スクロールバーを抜いた横幅を取得
	const setFillWidth = () => {
		const vw = Math.floor(document.documentElement.clientWidth);
		document.documentElement.style.setProperty("--vw", `${vw}px`);
	};
	setFillWidth();

	let vw = document.documentElement.clientWidth;
	window.addEventListener("resize", () => {
		if (vw === document.documentElement.clientWidth) {
			return;
		}
		vw = document.documentElement.clientWidth;
		setFillWidth();
		setFillHeight();
	});

	const html = document.documentElement;
	setTimeout(() => {
		document.body.classList.remove("preload");

		setTimeout(() => {
			html.classList.add("isLoaded");
		}, 400);
	}, 0);
}

export default function foundation() {
	init();
}
