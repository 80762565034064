/**
 * 参考HTML
	<div class="cModal">
		<div class="cModal__inner js-menu" aria-hidden="true">
			<div class="cModal__wrapper" tabindex="-1" role="dialog" aria-modal="true" aria-labelledby="dialog-title">
				<span id="dialog-title" class="visuallyHidden">これはモーダルです</span>

				<div class="cModal__overlay js-modal-overlay"></div>

				<div class="cModal__content" role="document">#contents</div>
			</div>
		</div>
	</div>

 * 参考CSS
	./src/assets/scss/components/modal.scss
 */

/**
 * @param {Object} Option
 * @param {string} Option.modalCass - モーダルのクラス名
 * @param {string} Option.openClass - モーダルを開くボタンのクラス名
 * @param {string} Option.htmlClass - htmlタグに付与するクラス名
 * @param {string} Option.globalContainer - グローバルコンテナー（モーダルはコンテナ外に記述してください）
 * @param {any} [Option.closeClass] - ある場合はクラス名を指定
 * @param {any} [Option.overlay] - ある場合はクラス名を指定
 */
export default function Modal({
	modalCass,
	openClass,
	htmlClass,
	globalContainer,
	closeClass = false,
	overlay = false,
}) {
	const targetModal = document.querySelector(modalCass);
	const container = document.querySelector(globalContainer);
	const openBtn = document.querySelectorAll(openClass);
	const closeBtn = document.querySelectorAll(closeClass);
	const overlayElement = document.querySelectorAll(overlay);

	if (!targetModal || !container) {
		console.error('Modal.js: targetModal or container is not found');
		return;
	}

	// tabbableな要素は Micromodal.js を参考に実装
	// https://github.com/ghosh/Micromodal/blob/master/lib/src/index.js
	const FOCUSABLE_ELEMENTS = [
		'a[href]',
		'area[href]',
		'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
		'select:not([disabled]):not([aria-hidden])',
		'textarea:not([disabled]):not([aria-hidden])',
		'button:not([disabled]):not([aria-hidden])',
		'iframe',
		'object',
		'embed',
		'[contenteditable]',
		'[tabindex]:not([tabindex^="-"])',
	];
	const modalLinkArry = targetModal.querySelectorAll(
		FOCUSABLE_ELEMENTS.join(',')
	);
	const focusLinkArry = document.querySelectorAll(
		FOCUSABLE_ELEMENTS.join(',')
	);

	let storedScrollY = 0;

	// modalLinkArryの全てにtabindex="-1"を設定
	modalLinkArry.forEach((el) => {
		el.setAttribute('tabindex', '-1');
	});

	// targetModalにinertを設定
	targetModal.setAttribute('inert', '');

	const handleKeyDown = (e) => {
		const firstFocusable = modalLinkArry[0];
		const lastFocusable = modalLinkArry[modalLinkArry.length - 1];

		if (e.key === 'Tab') {
			if (e.shiftKey) {
				if (document.activeElement === firstFocusable) {
					e.preventDefault();
					firstFocusable instanceof HTMLElement &&
						firstFocusable.focus();
				}
			} else {
				if (document.activeElement === lastFocusable) {
					e.preventDefault();
					firstFocusable instanceof HTMLElement &&
						firstFocusable.focus();
				}
			}
		}
	};

	function openModal() {
		if (!targetModal || !container) {
			return;
		}

		// 現在のスクロール位置を保存
		storedScrollY = window.scrollY;

		// 背景を固定
		// document.body.style.setProperty('position', 'fixed');
		// document.body.style.setProperty('top', `-${storedScrollY}px`);
		// document.body.style.setProperty('overflow', `hidden scroll`);
		// document.body.style.setProperty('width', '100%');

		document.documentElement.classList.add(htmlClass);

		targetModal.classList.add('is-open');
		targetModal.setAttribute('aria-hidden', 'false');

		container.classList.add('is-open');
		container.setAttribute('aria-hidden', 'true');

		// targetModalのinertを削除
		targetModal.removeAttribute('inert');

		// containerにinertを設定
		container.setAttribute('inert', '');

		// focusLinkArryの全てにtabindex="-1"を設定
		focusLinkArry.forEach((el) => {
			el.setAttribute('tabindex', '-1');
		});

		// focusLinkArryの中でmodalLinkArryに含まれるものはtabindexを削除
		modalLinkArry.forEach((el) => {
			el.removeAttribute('tabindex');
		});

		// 閉じるボタンがある場合は変更しない
		if (!closeClass) {
			openBtn.forEach((el) => {
				el.setAttribute('tabindex', '0');
				el.setAttribute('aria-label', 'メニューを閉じる');
			});
		} else {
			closeBtn.forEach((el) => {
				el.setAttribute('tabindex', '0');
			});
		}

		setTimeout(() => {
			const fv_target = document.querySelectorAll('.js-nav-target');
			fv_target.forEach(function (ele) {
				ele.classList.add('is-active');
			});
		}, 100);
	}

	function closeModal() {
		if (!targetModal || !container) {
			return;
		}

		// 背景を固定解除
		// document.body.style.removeProperty('position');
		// document.body.style.removeProperty('top');
		// document.body.style.removeProperty('overflow');
		// document.body.style.removeProperty('width');
		window.scrollTo(0, storedScrollY);

		openBtn.forEach((el) => {
			el.classList.remove('is-open');
		});

		document.documentElement.classList.remove(htmlClass);

		targetModal.classList.remove('is-open');
		targetModal.setAttribute('aria-hidden', 'true');

		container.classList.remove('is-open');
		container.setAttribute('aria-hidden', 'false');

		// targetModalにinertを設定
		targetModal.setAttribute('inert', '');

		// containerのinertを削除
		container.removeAttribute('inert');

		// focusLinkArryの全てのtabindexを削除
		focusLinkArry.forEach((el) => {
			el.removeAttribute('tabindex');
		});

		// focusLinkArryの中でmodalLinkArryに含まれるものはtabindex="-1"を設定
		modalLinkArry.forEach((el) => {
			el.setAttribute('tabindex', '-1');
		});

		// 閉じるボタンがある場合は変更しない
		if (!closeClass) {
			openBtn.forEach((el) => {
				el.setAttribute('aria-label', 'メニューを開く');
			});
		} else {
			openBtn.forEach((el) => {
				el.classList.remove('is-open');
			});
		}

		const fv_target = document.querySelectorAll('.js-nav-target');
		fv_target.forEach(function (ele) {
			ele.classList.remove('is-active');
		});
	}

	// モーダル内のみ、キーボード操作を有効化
	targetModal.addEventListener('keydown', handleKeyDown);

	// Safariで初期表示エラー防止
	window.addEventListener('load', () => {
		closeModal();
	});

	openBtn.forEach((el) => {
		el.addEventListener('click', () => {
			el.classList.toggle('is-open');

			if (el.classList.contains('is-open')) {
				openModal();
			} else {
				closeModal();
			}
		});
	});

	if (closeClass) {
		closeBtn.forEach((el) => {
			el.addEventListener('click', () => {
				closeModal();
			});
		});
	}

	if (overlay) {
		overlayElement.forEach((el) => {
			el.addEventListener(
				'click',
				(e) => {
					if (e.target === e.currentTarget) {
						closeModal();
					}
				},
				false
			);
		});
	}

	window.addEventListener('keydown', (e) => {
		if (e.key === 'Escape' || e.key === 'Esc') {
			closeModal();
		}
	});
}
