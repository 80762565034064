export default class SplitText {
	/**
	 * @class SplitText
	 * @constructor
	 * @param {String} els - 対象要素（メイン）
	 * @param {Object} Options - オプション
	 * @param {any} [Options.target] - 対象要素（複数行の場合は行ごとに指定）
	 * @param {String} [Options.className] - 付与するクラス
	 * @param {Number} [Options.baseDelay] - ベースになるディレイがある場合は指定（ms）
	 * @param {Array<any>} [Options.transition] - トランジションディレイがある場合は指定 (ms)
	 * @param {Array<any>} [Options.animation] - アニメーションディレイがある場合は指定 (ms)
	 *
	 * @example
		new SplitText(".js-s-block", {
			target: ".js-s-text",
			transition: [800],
		});
	 */

	// transition, animationはどちらか一方だけ指定する
	constructor(
		els,
		{
			target = false,
			className = 'char',
			baseDelay = 0,
			transition,
			animation,
		} = {}
	) {
		this.els = document.querySelectorAll(els);

		if (!this.els) {
			console.error(`SplitText: ${els} が見つかりませんでした。`);
			return;
		}

		this.className = className;
		this.baseDelay = baseDelay;
		this.transition = transition;
		this.animation = animation;

		// 対象要素が複数行の場合は、それぞれに対応する
		// テキストを分割する
		this.target = this.els;

		this.els.forEach((element) => {
			if (target) {
				this.target = element.querySelectorAll(target);

				this.target.forEach((target) => {
					const span = document.createElement('span');
					span.innerHTML = target.innerHTML;
					span.classList.add('uVisuallyHidden');
					target.insertAdjacentElement('afterend', span);

					/**
					 * @type {Array<any>}
					 */
					this.chars = target.innerHTML.trim().split('');
					target.innerHTML = this._splitText();
				});
			} else {
				const span = document.createElement('span');
				span.innerHTML = element.innerHTML;
				span.classList.add('uVisuallyHidden');
				element.insertAdjacentElement('afterend', span);

				/**
				 * @type {Array<any>}
				 */
				this.chars = element.innerHTML.trim().split('');
				element.innerHTML = this._splitText();
			}
		});

		// ディレイを付与する
		if (this.transition || this.animation) {
			this._charsAddDelay();
		}
	}

	_splitText() {
		return this.chars.reduce((acc, curr) => {
			if (curr === '＆') {
				curr = curr.replace('＆', '&amp;');
			} else if (curr.match(/\s+/)) {
				curr = curr.replace(/\s+/g, '&nbsp;');
			}

			return `${acc}<span class="${this.className}" aria-hidden="true">${curr}</span>`;
		}, '');
	}

	_charsAddDelay() {
		this.els.forEach((element) => {
			const chars = element.querySelectorAll(`.${this.className}`);
			const transition = this.transition;
			const animation = this.animation;

			chars.forEach((char, index) => {
				let transitionDelayTime = '';
				let animationDelayTime = '';

				if (transition) {
					if (transition.length === 1) {
						transitionDelayTime =
							Math.round(transition[0] * index + this.baseDelay) /
								1000 +
							's';
					} else {
						transition.forEach((time) => {
							transitionDelayTime += `${
								Math.round(time * index + this.baseDelay) / 1000
							}s, `;
						});

						transitionDelayTime = transitionDelayTime.slice(0, -2);
					}
				}

				if (animation) {
					if (animation.length === 1) {
						animationDelayTime =
							Math.round(animation[0] * index + this.baseDelay) /
								1000 +
							's';
					} else {
						transition.forEach((time) => {
							animationDelayTime += `${
								Math.round(time * index + this.baseDelay) / 1000
							}s, `;
						});

						animationDelayTime = animationDelayTime.slice(0, -2);
					}
				}

				if (this.animation && this.transition) {
					char.setAttribute(
						'style',
						`animation-delay: ${animationDelayTime}; transition-delay: ${transitionDelayTime};`
					);
				} else if (this.animation) {
					char.setAttribute(
						'style',
						`animation-delay: ${animationDelayTime};`
					);
				} else if (this.transition) {
					char.setAttribute(
						'style',
						`transition-delay: ${transitionDelayTime};`
					);
				}
			});
		});
	}
}
