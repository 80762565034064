import { gsap } from "gsap";

const sine = {
	in: gsap.parseEase("sine.in"),
	out: gsap.parseEase("sine.out"),
};

const expo = {
	in: gsap.parseEase("expo.in"),
	out: gsap.parseEase("expo.out"),
	inOut: gsap.parseEase("expo.inOut"),
};

const back = {
	in: gsap.parseEase("back.in"),
	out: gsap.parseEase("back.out"),
};

const Ease = {
	sine2: {
		in: (i) => {
			return sine.in(sine.in(i));
		},
		out: (i) => {
			return sine.out(sine.out(i));
		},
	},
	expo2: {
		in: (i) => {
			return expo.in(expo.in(i));
		},
		out: (i) => {
			return expo.out(expo.out(i));
		},
	},
	sineExpo: (i) => {
		return expo.out(sine.in(i));
	},
	sineExpo2: (i) => {
		const t = expo.inOut(i);
		return expo.in(i) * (1 - t) + sine.out(i) * t;
	},
	backSine: (i) => {
		return sine.out(back.out(i));
	},
};

export default Ease;
